import React, { useState, useEffect } from "react"
import {Typography, Grid} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';

import Layout from "../../components/layout"
import Event from './eventTemplate';
import { useStaticQuery, graphql } from "gatsby";
import EventsShow from "../../api/events/events";

const useStyles = makeStyles(theme => ({
  margin: {
    fontFamily:"Arial Black",
    width:"100%",
    display:'flex',
    alignItems:"center",
    justifyContent:"center"

    },
    GridMonths:{
      minHeight:"250px",
      height:"500px",
    },
    noEvent:
    {
      fontFamily:"Arial Black",
      width:"100%",
      textAlign:"center",
      marginTop: '100px',
    }
}));



const Events = () => {
  const classes = useStyles();  
  const [eventsData, setEventsData] = useState([]);
  
  useEffect(()=>{
    function DeleteFalseEvent(event){
      const eventDate = '2000-02-19T16:49:44.563Z';
      return event.length === 1
        ? event[0].eventDate === eventDate
          ? []
          : event
        : event;
    };
    async function fetchEvents(){
      let data = await EventsShow();      
      try {
          const months = [
              {Name: 'Enero', Events: DeleteFalseEvent(data.data.events.January)},
              {Name: 'Febrero', Events: DeleteFalseEvent(data.data.events.February)},
              {Name: 'Marzo', Events: DeleteFalseEvent(data.data.events.March)},
              {Name: 'Abril', Events: DeleteFalseEvent(data.data.events.April)},
              {Name: 'Mayo', Events: DeleteFalseEvent(data.data.events.May)},
              {Name: 'Junio', Events: DeleteFalseEvent(data.data.events.June)},
              {Name: 'Julio', Events: DeleteFalseEvent(data.data.events.July)},
              {Name: 'Agosto', Events: DeleteFalseEvent(data.data.events.August)},
              {
                Name: 'Septiembre',
                Events: DeleteFalseEvent(data.data.events.September),
              },
              {Name: 'Octubre', Events: DeleteFalseEvent(data.data.events.October)},
    
              {
                Name: 'Noviembre',
                Events: DeleteFalseEvent(data.data.events.November),
              },
    
              {
                Name: 'Diciembre',
                Events: DeleteFalseEvent(data.data.events.December),
              },
            ]
          await setEventsData(months)
      } catch (error) {
       console.log(error) 
      }
    }
    if(eventsData.length===0){
      fetchEvents()
    }
  })
  /* const data= useStaticQuery(graphql`query {
      allRestApiApiEventsShowall {
        edges {
          node {
            data {
              events {
                January {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                  
                }
                February {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                March {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                April {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                May {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                  
                }
                June {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                July {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                August {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                September {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                October {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                November {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
                December {
                  content
                  eventDate(formatString: "dddd DD/MMMM/YYYY", locale: "es-MX")
                  description
                  img
                  place
                  register
                  title
                }
              }
            }
          }
        }
      }
    }
  `) */
  //console.log(data.allRestApiApiEventsShowall.edges[0].node.data.events)
  //const monthsES = 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_')
  return(
    <Layout>
      <Typography className={classes.margin}  gutterBottom variant="h4" >Eventos</Typography>
      {(eventsData.length>0?(<Grid 
      container
      direction="row"
      justify="center"
      alignItems="center">
      { 
       eventsData.map((month, index) => {
         return( <Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.GridMonths} >
          <Event state={{month: month.Name, events: month.Events }} key={index}/>
          </Grid>)
       })}
      </Grid>):(
        <Typography className={classes.noEvent}  gutterBottom variant="h4" >Sin Eventos Registrados</Typography>
      ))}
    </Layout>
  )
}
export default Events
