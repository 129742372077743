import api from "../baseurl";
const EventsShow = async () => {
    console.log(new Date().getFullYear())

      const status = await api.get('events/showall').json();
      return status ;
      
    };
    
    export default EventsShow ; 
  